import { Box, Container, Divider, Grid, styled, Typography } from "@mui/material";
import React from "react";
import { workGalleryText } from "../../texts/workGalleryText";
import { GalleryTile } from "../models/galleryTile";

const Gallery = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    width: '100vw',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
}));

const WorkGallery = () => {
    return (
        <Gallery
            id='gallery'
            sx={{boxShadow: 2, pt: 5, pb: 5,}}  
            maxWidth={false}
            >
                <Typography
                    variant="h3"
                    sx={{
                        textAlign: 'center',
                        fontSize: {
                            xs: 'h4.fontSize',
                            sm: 'h4.fontSize',
                            md: 'h4.fontSize',
                            lg: 'h3.fontSize',
                        }
                    }} >
                    {workGalleryText.title}
                </Typography>
                <Box 
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mb: 5,
                    }}                
                >
                    <Divider sx={{ backgroundColor: 'black', mt: 2, mb: 2, height: 7, 
                        width:{xs:100, sm: 200, md: 300, lg: 400} }} />
                </Box>
                <Box sx={{ maxWidth:"1920px", display: 'flex', width: '100%', position: 'stretch' }} >
            <Grid
                id='gallery'
                container
                 display={"flex"}
                justifyContent="center"
                width={"100%"}
                rowSpacing={1}
                rowGap={{ xs: 0.5, sm: 1, md: 2 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                columns={{ xs: 2, sm: 2, md: 12, xl:18 }}
                sx={{
                    ml: { xl: 5, lg: 15, md: 5 },
                    mr: { xl: 5, lg: 15, md: 5 },
                }}>
                {workGalleryText.works.map((image) => (
                    <Grid item xs={6} margin={0}>
                        <GalleryTile image={image} />
                    </Grid>
                ))}
            </Grid>
        </Box>
        </Gallery>
    );
};

export default WorkGallery;