
export interface QNAtext {
    title: string;
    questions: Question[];
}

export type Question =  {
    question: string;
    answer: string;
}


export const qnaText: QNAtext = {
    title: "Vy máte problém, my máme riešenie!",
    questions: [
        {
            question: "Nefunguje Vám Vaše okno? Nedá sa zatvoriť, otvoriť alebo vyklopiť?",
            answer: "Ponúkame opravu a výmenu všetkých typov kovaní na plastové, drevené aj hliníkové okná."
        },
        {
            question: "Rosia sa Vám okná?",
            answer: "Príčin môže byť viacero. Najčastejšie to spôsobuje zlé tesnenie okna medzi krídlom a rámom, alebo nedostatočné utesnenie okna po obvodovej špáre. Netreba však zabúdať aj na správne a dostatočné vetranie. V každom prípade je to veľmi vážny problém, ktorý okrem životnosti okna ohrozuje aj Vaše zdravie, nakoľko všade, kde sa rosia okná, časom vznikajú aj plesne. Neváhajte nás kontaktovať. S riešením takýchto problémov máme bohaté skúsenosti."
        },
        {
            question: "Ťahá Vám cez okná?",
            answer: "Pravdepodobne budete potrebovať výmenu tesnenia, alebo Vám nefunguje správne kovanie a nepriťahuje dostatočne krídlo k rámu. Prípadne Vám môže ťahať medzi parapet a okno. Niekedy stačí aj správne nastaviť okná."
        },
        {
            question: "Ťažko sa Vám zatvárajú okná?",
            answer: "Najčastejšou príčinou sú zle nastavené okná alebo zle fungujúce kovanie. Včasným nastavením Vašich okien u nás môžete zabrániť ich poškodeniu a ušetriť nemalé peniaze."
        },
        {
            question: "Máte iný vážny problém, s ktorým si neviete poradiť?",
            answer: "Kontaktujte nás aj mimo pracovnú dobu alebo počas pracovných sviatkov. Ak to bude možné, prídeme a pomôžeme Vám."
        },
        {
            question: "Koľko budem platiť za opravu?",
            answer: "Obhliadku v Košiciach robíme bezplatne. Diagnostikujeme závadu, navrhneme vhodné riešenie a pripravíme cenovú kalkuláciu."
        }
    ]
}

export const qnaBlindsText: QNAtext = {
    title: "Vy máte problém, my máme riešenie!",
    questions: [
        {
            question: "Nefunguje Vám Vaše okno? Nedá sa zatvoriť, otvoriť alebo vyklopiť?",
            answer: "Ponúkame opravu a výmenu všetkých typov kovaní na plastové, drevené aj hliníkové okná."
        },
        {
            question: "Rosia sa Vám okná?",
            answer: "Príčin môže byť viacero. Nesprávne alebo nedostatočné vetranie, zlé tesnenie okna medzi krídlom a rámom, alebo nedostatočné utesnenie okna po obvodovej špáre. S každým z týchto problémov Vám vieme pomôcť."
        },
        {
            question: "Ťahá Vám cez okná?",
            answer: "Pravdepodobne budete potrebovať výmenu tesnenia, alebo Vám nefunguje správne kovanie a nepriťahuje dostatočne krídlo k rámu. Prípadne Vám môže ťahať medzi parapet a okno. Niekedy stačí aj správne nastaviť okná."
        },
        {
            question: "Ťažko sa Vám zatvárajú okná?",
            answer: "Najčastejšou príčinou sú zle nastavené okná alebo zle fungujúce kovanie. Včasným nastavením Vašich okien u nás môžete zabrániť ich poškodeniu a ušetriť nemalé peniaze."
        },
        {
            question: "Máte iný vážny problém, s ktorým si neviete poradiť?",
            answer: "Kontaktujte nás aj mimo pracovnú dobu alebo počas pracovných sviatkov. Ak to bude možné, prídeme k Vám a skúsime Vám pomôcť."
        },
        {
            question: "Koľko budem platiť za opravu?",
            answer: "Obhliadku v Košiciach robíme bezplatne. Diagnostikujeme závadu, navrhneme vhodné riešenie a pripravíme cenovú kalkuláciu."
        }
    ]
}
