// EmailTemplate.ts

interface EmailTemplateProps {
    customerName: string;
    customerEmail: string;
    phoneNumber: string;
    serviceRequested: string;
    websiteUrl: string;
    sendDate: string;
  }
  
  const EmailTemplate = ({
    customerName,
    customerEmail,
    phoneNumber,
    serviceRequested,
    websiteUrl,
    sendDate,
  }: EmailTemplateProps): string => {
    return `
      <!DOCTYPE html>
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #666;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
          }
          .container {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          .header {
            text-align: center;
            background-color: #00910c;
            padding: 20px;
            border-radius: 8px 8px 0 0;
            color: #fff;
          }
          .header h1 {
            margin: 0;
            font-size: 24px;
          }
          .content {
            padding: 20px;
          }
          .content p {
            margin: 10px 0;
          }
          .content .label {
            font-weight: bold;
            color: #555;
          }
          .footer {
            text-align: center;
            padding: 20px;
            font-size: 12px;
            color: #999;
          }
          .footer a {
            color: #007BFF;
            text-decoration: none;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="header">
            <h1>Nová požiadavka!</h1>
          </div>
          <div class="content">
            <p>Dobrý deň,</p>
            <p>Zo stránky <strong>alvip-okno.sk</strong> prišla nová požiadavka od zákazníka.</p>
            <p><span class="label">Meno odosialateľa:</span> ${customerName}</p>
            <p><span class="label">Email:</span> <a href="mailto:${customerEmail}">${customerEmail}</a></p>
            <p><span class="label">Telefónne číslo:</span> ${phoneNumber}</p>
            <p><span class="label">Dátum odoslania:</span> ${sendDate}</p>
            <p><span class="label">Obsah správy:</span></p>
            <p>${serviceRequested}</p>
            <br>
            <p>Zákazníkovi môžte odpovedať priamo na túto správu, alebo na hore uvedený email.</p>
          </div>
          <div class="footer">
            <p>Tento email bol generovaný automaticky zo stránky <a href="${websiteUrl}">${websiteUrl}</a> </p>
          </div>
        </div>
      </body>
      </html>
    `;
  };
  
  export default EmailTemplate;
  