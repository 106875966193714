import { Box, Grid, Slide, Stack, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "./footer";
import NavBar from "./nav-bar";

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
}));

interface DetailsProps {
    title: string;
    content: string;
    background: string;
}

export const Forging: React.FC<DetailsProps> = ({ title, content, background }) => {

    const [dense, setDense] = React.useState(false);
    const [animate, setAnimate] = useState(false);
    const [secAnimate, setSecAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
    }, []);
    useEffect(() => {
        if (animate) {
            const timeoutId = setTimeout(() => {
                setSecAnimate(true);
            }, 300); // delay 500ms
            return () => clearTimeout(timeoutId);
        }
    }, [animate]);


    return (
        <Box sx={{ width: '100%', position: 'realtive', backgroundColor: 'primary.main', height: '100vh' }}>
            <Stack
                justifyContent="center"
            >
                <NavBar />
                <Box sx={{
                    boxShadow: 10,
                    minHeight: '35vh',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column', // Ensure children are stacked vertically
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    padding: .01,
                    width: '100vw',
                    backgroundImage:
                        `url(${process.env.PUBLIC_URL + background
                        })`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <Slide
                        direction="left"
                        in={animate}
                        timeout={400}>
                        <StyledBox sx={{
                            boxShadow: 10,
                            top: 0,
                            width: { xs: '100vw', sm: '60vw', md: '50vw', lg: '45vw', xl: '50vw' },
                            p: { xs: 3, sm: 5 },
                            mt: { xs: 5, sm: 20, md: 25 },
                            mr: { xs: 0, sm: 5, md: 8 },
                            mb: { xs: 15, sm: 5, md: 8 },
                        }}>
                            <Typography
                            variant="h3"
                                sx={{
                                    mb: 1,
                                    textAlign: 'left',
                                    fontSize: {
                                        xs: 'h5.fontSize',
                                        sm: 'h4.fontSize',
                                        md: 'h4.fontSize',
                                        lg: 'h3.fontSize',
                                    },
                                    fontWeight: 800,
                                    color: 'secondary.light',
                                }}
                            >{title}</Typography>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontSize: {
                                        xs: 'subtitle1.fontSize',
                                        sm: 'h6.fontSize',
                                        md: 'h6.fontSize',
                                        lg: 'h5.fontSize',
                                    },
                                    color: 'white',
                                }}
                            >{content}</Typography>
                            <Grid
                                container rowSpacing={1}
                                display={"flex"}
                                padding={2}
                                justifyContent="center" // Center horizontally in the container
                                rowGap={{ xs: 1, sm: 2, md: 3 }}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                columns={{ xs: 12, sm: 12, md:12, lg: 18, xl: 36 }}
                                sx={{
                                    borderRadius: 1, boxShadow: 10, backgroundColor: "secondary.main", mt: 5
                                }}>
                                <Grid xs={6} sx={{
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    height: 100, backgroundImage:
                                        `url(${process.env.PUBLIC_URL}/pictures/forging/Gretsch-unitas-gruppe-dachmarke.webp)`,
                                    flexGrow: 1
                                }} />
                                <Grid xs={6} sx={{
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    height: 100, backgroundImage:
                                        `url(${process.env.PUBLIC_URL}/pictures/forging/logo-winkhaus.webp)`,
                                    flexGrow: 1
                                }} />
                                <Grid xs={6} sx={{
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    height: 100, backgroundImage:
                                        `url(${process.env.PUBLIC_URL}/pictures/forging/macologo-d1c71215.webp)`,
                                    flexGrow: 1
                                }} />
                                <Grid xs={6} sx={{
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    height: 100, backgroundImage:
                                        `url(${process.env.PUBLIC_URL}/pictures/forging/Roto-Logo.webp)`,
                                    flexGrow: 1
                                }} />
                                <Grid xs={6} sx={{
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    height: 100, backgroundImage:
                                        `url(${process.env.PUBLIC_URL}/pictures/forging/schuco-logo-png-transparent.webp)`,
                                    flexGrow: 1
                                }} />
                                <Grid xs={6} sx={{
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    height: 100, backgroundImage:
                                        `url(${process.env.PUBLIC_URL}/pictures/forging/Siegenia-logo.webp)`,
                                    flexGrow: 1
                                }} />


                            </Grid>
                        </StyledBox>
                    </Slide>
                </Box>
                <Footer />
            </Stack>
        </Box>
    );
};