import { Title } from "@mui/icons-material";

export const serviceEmergencyText = {
    title: "Havarijný servis",
    description: [
        "Rýchla pomoc pri rôznych poruchách", 
        "Aj mimo pracovných hodín",
    ],
    more_info: 
        `Neviete otvoriť alebo zatvoriť vchodové dvere?
Spadlo vám krídlo?
Okno sa nedá zatvoriť a vonku sa čerti ženia?
Máte iný vážny problém s ktorým si neviete poradiť?
Zavolajte nám a my Vám pomôžeme.`
    
}