import React, { useEffect } from 'react'
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { Box, Collapse, IconButton, IconButtonProps, Stack, styled, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../theme';


const generateImagePaths = (count: number, extension: string = 'webp') => {
    const paths = [];
    for (let i = 1; i <= count; i++) {
      paths.push(`${i}.${extension}`);
    }
    return paths;
  };

  interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


export default function ColorsGrid(){
    const imagePaths = generateImagePaths(44);
    const isExpanderVisibale = useMediaQuery(theme.breakpoints.up('xl'));
    const smallItemStyles: React.CSSProperties = {
      cursor: 'pointer',
      objectFit: 'cover',
      //width: '150px',
      //height: '150px',
    };
    const [expanded, setExpanded] = React.useState(false);


    useEffect(() => {
        if (isExpanderVisibale) {
          setExpanded(true);
        } else {
          setExpanded(false);
        }
      }, [isExpanderVisibale]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
    return (
        <Stack >
            <Box sx={{ display: 'flex',justifyContent:"center", pb:4}}>
      <ExpandMore
        sx={{display: {xs:'block' ,xl: 'none'}, }}
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon fontSize='large' />
        </ExpandMore>
        </Box>
            <Collapse in={expanded} timeout="auto" collapsedSize={"45vh"}>
      <Gallery>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(125px, 1fr))',
            rowGap: 10,
            //gridGap: 5,
          }}
        >
        {imagePaths.map((path) => {
            const thumbnailPath = process.env.PUBLIC_URL + `/pictures/window_colors/thumbs/${path}`;
            const originalPath = process.env.PUBLIC_URL + `/pictures/window_colors/${path}`;
            return (
                <Item<HTMLImageElement>
                cropped
                original={originalPath}
                thumbnail={thumbnailPath}
                width="1000"
                height="1000"
                >
                {({ ref, open }) => (
                    <Box sx={{ display: 'flex', height: "125px" }}>
                    <img
                        style={smallItemStyles}
                        src={thumbnailPath}
                        ref={ref}
                        onClick={open}
                    />
                    </Box>
                )}
                </Item>
            );
        })}
        </div>
      </Gallery>
      </Collapse>
      </Stack>
    );
}

