
export interface Gallery {
    title: string;
    works: Work[];
}

export type Work =  {
    url: string;
    title: string;
    afterurl: string;
    description?: string;
}

export const workGalleryText: Gallery = {
    title: 'Príklady z praxe',
    works: [
        {
            url: '/pictures/examples/test1.webp',
            title: 'Výmena kovania',
            afterurl: '/pictures/examples/test1.webp',
            description: `Ak je samotný plast okna kvalitný a bez viditeľného poškodenia, nie je potrebné pri 
            poruche funkčnosti zvažovať hneď výmenu celého okna. Nefunkčné kovanie vymeníme za nové, okno 
            správne nastavíme a vycentrujeme. Takto opravené okno Vám môže slúžiť ešte niekoľko desiatok rokov.`
        },
        {
            url: '/pictures/examples/test2.webp',
            title: 'Rosenie okien a vznik plesní',
            afterurl: '/pictures/examples/test2.webp',
            description: `Rosia sa Vám okná? Príčin môže byť viacero. Najčastejšie to spôsobuje zlé tesnenie 
            okna medzi krídlom a rámom alebo nedostatočné utesnenie okna po obvodovej špáre. Netreba však 
            zabúdať ani na správne a dostatočné vetranie. Tento problém je veľmi vážny, pretože nielenže 
            ohrozuje životnosť okna, ale môže aj negatívne ovplyvniť Vaše zdravie, nakoľko všade, kde sa 
            rosia okná, časom vznikajú plesne. Neváhajte nás kontaktovať. S riešením takýchto problémov 
            máme bohaté skúsenosti.`
        },
        {
            url: '/pictures/examples/test3.webp',
            title: 'Výmena skla',
            afterurl: '/pictures/examples/window-repair.webp',
            description: `Rozbilo sa Vám sklo na plastovom, drevenom alebo hliníkovom okne? Zabezpečujeme 
            kompletný servis pri takýchto udalostiach. Sklo zameriame, vyrobíme potrebný rozmer, staré sklo 
            bezpečne zdemontujeme, odvezieme a zlikvidujeme. Nové sklo osadíme, vycentrujeme a zasklíme. 
            Okno následne nastavíme, aby bola zachovaná jeho maximálna funkčnosť. Dodávame širokú paletu 
            izolačných dvojskiel, trojskiel, čírych, reflexných, ornamentových, prípadne kalených alebo 
            iných špeciálnych skiel. V niektorých prípadoch vieme nahradiť termoizolačné trojsklo za 
            dvojsklo.`
        },
        {
            url: '/pictures/examples/IMG_3142.webp',
            title: 'Havarijný servis',
            afterurl: '/pictures/examples/IMG_3142.webp',
            description: `Neviete otvoriť alebo zatvoriť vchodové dvere? Spadlo Vám krídlo? Okno sa nedá 
            zatvoriť a vonku sa čerti ženia? Máte iný vážny problém, s ktorým si neviete poradiť? Zavolajte 
            nám a my Vám pomôžeme.`
        },
        {
            url: '/pictures/examples/IMG_3149.webp',
            title: 'Vylomený pánt',
            afterurl: '/pictures/examples/IMG_3149.webp',
            description: `Zabezpečujeme opravu všetkých typov plastových okien a dverí.`
        },
    ]
};
