import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSlots, AccordionSummary, Box, Collapse, Icon, Stack, Typography, Zoom, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import React from 'react';
import { useInView } from 'react-intersection-observer';


const ChatBubble = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    maxWidth: 1500,
    boxShadow: theme.shadows[5],
    borderRadius: '0px 25px 25px 25px',
}));

const ChatBubbleAnswer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    width: 'auto',
    textAlign: 'right',
    maxWidth: 1500,
    boxShadow: theme.shadows[15],
    padding: theme.spacing(3),
    borderRadius: '25px 0px 25px 25px',
}));

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));


interface RotatingIconProps {
    rotate: boolean;
}

interface QnaBubbleProps {
    question: string;
    answer: string;
}

const ExpandMore: React.FC<RotatingIconProps> = ({ rotate }) => {
    const iconStyle = {
        transition: 'transform 0.3s ease-in-out',
        transform: rotate ? 'rotate(0deg)' : 'rotate(-90deg)',
    };
    return (
        <Icon style={iconStyle} > <ExpandMoreIcon /></Icon>
    );
};



const QnaBubble: React.FC<QnaBubbleProps> = ({ question, answer }) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };


    const { ref, inView } = useInView({
        triggerOnce: true,
    });


    return (
                <Accordion
                    disableGutters
                    expanded={expanded}
                    onChange={handleExpansion}
                    elevation={0}
                    sx={{
                        backgroundColor: 'transparent',
                        padding: 0,
                        width: 'auto',
                    }}

                    slots={{ transition: Zoom as AccordionSlots['transition'] }}
                    slotProps={{ transition: { timeout: 400 } }}
                >
                    <AccordionSummary ref={ref}
                    sx={{width: 'fit-content',
                        mr: { xs: 0, lg: 25 }
                    }}
                    >
                    <Zoom in={inView} timeout={1000}>
                        <ChatBubble sx={{
                            width: { xl: '40vw', lg: '55vw', md: '60vw', sm: '75vw', xs: '80vw' },
                            maxWidth: '950px',
                            padding: { xs: 1.5, md: 3, lg: 5 },
                        }}>
                            <Stack direction="row" spacing={1} justifyContent="space-between">
                                <Typography 
                                sx={{
                                    fontSize: {
                                        xs: 'h7.fontSize',
                                        sm: 'h6.fontSize',
                                        md: 'h5.fontSize',
                                        lg: 'h5.fontSize',
                                        xl: 'h5.fontSize',
                                    }

                                }}
                                >{question}</Typography>
                                <ExpandMore rotate={expanded} />
                            </Stack>
                        </ChatBubble>
                        </Zoom>
                    </AccordionSummary>
                    <Collapse in={expanded}>
                        <AccordionDetails sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ChatBubbleAnswer sx={{
                                ml: { xs: 5, lg: 25, md: 15, sm: 10 },
                                padding: { xs: 1.5, md: 3, lg: 5 }
                            }}>
                                <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontSize: {
                                        xs: 'h7.fontSize',
                                        sm: 'h6.fontSize',
                                        md: 'h5.fontSize',
                                        lg: 'h5.fontSize',
                                    }

                                }}>{answer}</Typography>
                            </ChatBubbleAnswer>
                        </AccordionDetails>
                    </Collapse>
                </Accordion>
    );
};

export default QnaBubble;