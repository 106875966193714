export type Net = {
    name: string;
    description: string[];
    adventages: string[];
    images: string[];
};


export interface WindowNetsProps {
    title: string;
    image: string;
    subtitle: string;
    landingBox: string;
    nets: Net[];
}

export const WindowNetsText: WindowNetsProps = {
    title: "OKENNÉ SIETE",
    subtitle: "Vetrajte podľa potreby, a nepustite dovnútra dotieravý hmyz.",
    landingBox: "V našej ponuke nájdete siete proti hmyzu na plastové, hliníkové aj drevené eurookná.",
    image: "/pictures/top_bg.webp",
    nets: [
        {
            name: "PEVNÉ SIETE PROTI HMYZU",
            description: [
                "Pevná hliníková sieť proti hmyzu s falcom 19/8 alebo 25/10, uchytenie háčikom na rám okna.",
                "Pevná hliníková sieť proti hmyzu, profil 25/10 bez falcu, uchytenie na rám okna zvrtlíkom našrubovaným do rámu.",
            ],
            adventages: [
                "Široká paleta farieb hliníkových profilov.",
                "Možnosť individuálneho striekania rámov podľa zadania RAL.",
                "Imitácie dreva.",
                "Možnosť vyhotovenia aj v prevedení siete proti hlodavcom."
            ],
            images: ["window_net.webp"],
        },
        {
            name: "ROLOVACIE SIETE PROTI HMYZU",
            description: [
                "Svoje využitie nájdu najmä pri jednokrídlových oknách, keď potrebujete zabezpečiť okno proti hmyzu a zároveň si ponechať možnosť manipulácie skrz rám, či už z dôvodu vešania prádla na sušiak cez okno alebo napr. vyhodiť kľúče cez okno.",
                "Hliníkové telo rámu zabezpečí dlhú životnosť.",
            ],
            adventages: [
                "Široká paleta farieb hliníkových profilov.",
                "Možnosť individuálneho striekania rámov podľa zadania RAL.",
                "Imitácia dreva."
            ],
            images: ["roll-up.webp"],
        },
        {
            name: "PLISSÉ SIETE PROTI HMYZU",
            description: [
                "Využitie podobne ako rolovacie siete majú všade tam, kde chcete ochrániť interiér od hmyzu a zároveň potrebujete voľný prístup k exteriéru. Za o čosi vyššiu cenu Vám však ponúknu vyšší komfort. Na ovládanie nepotrebujete hľadať šnúrku a vyhnete sa aj buchotu rolovacieho bubna.",
                "Hliníkové telo rámu zabezpečí dlhú životnosť.",
            ],
            adventages: [
                "Široká paleta farieb hliníkových profilov.",
                "Možnosť individuálneho striekania rámov podľa zadania RAL.",
                "Imitácia dreva."
            ],
            images: ["unnamed4.webp"],
        },

    ]
};
