import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#408a40',
    },
    secondary: {
      main: '#d0b712',
    },
    success: {
      main: '#1f947e',
    },
    info: {
      main: '#03a9f4',
    },
    error: {
      main: "#bd0000", // You can customize this color as needed
    },
    background: {
      default: '#fffddb',
      paper: '#f5f1a9',
    },
  
  },
  typography: {
    fontFamily: '"Titillium Web", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 600,
    fontWeightBold: 900,
    fontWeightMedium: 900,
  },
});

export default theme;
