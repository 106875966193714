import ZoomInIcon from '@mui/icons-material/ZoomIn';
import OpenInFullIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, Fade, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation, useParams } from "react-router-dom";
import {
  TransformComponent,
  TransformWrapper,
  useControls
} from "react-zoom-pan-pinch";
import { ProductsTexts } from "../../texts/technicalSpecsText";
import ContactForm from "../main/contactForm";
import Footer from "../main/footer";
import NavBar from "../main/nav-bar";
import ScrollTop from "../models/ScrollToTop";
import ColorsGrid from "./colorsGrid";
import InfoTabs from "./infoTab";



export const WindowDetailsSpecs = () => {
  const { productId } = useParams<{ productId: string }>();
  const products = ProductsTexts[productId as string];
  const location = useLocation();
  const targetId = location.state?.targetId;
  const theme = useTheme();

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  // Step 3: Determine the current viewport size
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const imgXs = useMediaQuery(theme.breakpoints.only('xs'));
  const imgMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));



  let imageHeight = imgXs ? '100vw' : imgMd ? '50vw' : '40vw';


  // Step 4: Set thumbWidth based on the current breakpoint
  let thumbWidth;
  if (isXs) {
    thumbWidth = 50; // Smaller screens
  } else if (isSm) {
    thumbWidth = 70; // Medium screens
  } else if (isLg) {
    thumbWidth = 100; // Larger screens
  }

  useEffect(() => {
    console.log(productId);
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }

  }, [targetId]);

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <>
        <Stack sx={{mb:0}} direction="row" justifyContent="center" alignItems="center">
          <ZoomInIcon color="secondary" sx={{cursor: 'pointer', mr: 1 }} fontSize="large" onClick={() => zoomIn()} />
          <ZoomOutIcon color="secondary"sx={{cursor: 'pointer', mr: 1 }} fontSize="large" onClick={() => zoomOut()} />
          <OpenInFullIcon color="secondary"sx={{cursor: 'pointer', mr: 1 }} fontSize="large" onClick={() => resetTransform()} />
        </Stack>
        
      </>
    );
  };

  return (
    <Box sx={{ width: '100%', position: 'realtive', backgroundColor: 'primary.main' }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        display='flex'
      >
        <NavBar />
        <Box sx={{
          display: 'flex', // Enable flexbox
          alignItems: 'center',
          flexDirection: 'column',
          pb: 5, pt: { xs: 0, sm: 2, md: 5 }, width: '100%', height: "auto", position: 'realtive', backgroundColor: 'background.paper'
        }}>
          <Grid
            maxWidth={"1920px"}
            container
            display={"flex"}
            rowSpacing={1}
            rowGap={{ xs: 1, sm: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 5, sm: 12, lg: 12, xl: 18 }}
            sx={{
              pl: { xl: 20, lg: 15, md: 10, sm: 5 },
              pr: { xl: 20, lg: 15, md: 10, sm: 5 },
            }}>
            <Grid
              ref={ref}
              item xs={5}
              sm={12}
              md={6}
              xl={8}>
              <Fade timeout={700} in={inView}>
                <Box sx={{ height: "auto", boxShadow: 10, p: { xs: 2, md: 5 }, backgroundColor: "primary.dark", borderRadius: { xs: 0, md: 2 } }}>
                  <Typography align="left" variant="h4" sx={{ color: "secondary.light", pb: 5, }}>{products.name}</Typography>

                  <Carousel renderThumbs={(children: React.ReactNode[]) =>
                    children.map(item =>
                      React.isValidElement(item) ? (
                        <img key={item.key} src={`${process.env.PUBLIC_URL}/pictures${item.key}`} alt="" />
                      ) : null
                    ) as React.ReactChild[]
                  }
                    showStatus={false} dynamicHeight swipeable={false} showArrows infiniteLoop stopOnHover transitionTime={500} thumbWidth={thumbWidth}>

                    {products.images.map((image, index) => (

                      <Box
                        key={image}
                        sx={{
                          backgroundColor: 'primary.main',
                          display: 'flex',
                          height: { xs: '150vw', md: '50vw', xl: '50vw' },
                          justifyContent: 'center',
                        }}
                      >

                        <TransformWrapper >
                          <Stack direction="column" justifyContent="top" alignItems="center">
                              <Controls />
                            <TransformComponent contentStyle={{  maxHeight: "700px", height: imageHeight, }}>
                              <img
                                style={{paddingBottom:50, width: '100%', height: '100%', objectFit: 'contain' }}
                                src={process.env.PUBLIC_URL + "/pictures" + image}
                                alt={`product-${index}`}
                              >
                              </img>
                            </TransformComponent>
                            <Box height={"150px"}/>
                          </Stack>
                        </TransformWrapper>
                      </Box>
                    ))}

                  </Carousel>

                </Box>
              </Fade>
            </Grid>
            <Grid item xs={6}
              sm={12}
              md={6}
              xl={10}>
              <Fade timeout={700} in={inView}>
                <Box sx={{ boxShadow: 10, backgroundColor: "secondary.light", p: 5, borderRadius: { xs: 0, md: 2 } }}>
                  <Typography align="left" variant="h4" sx={{ color: "primary.dark", }}>Ponuka farieb</Typography>
                  <ColorsGrid />
                </Box>
              </Fade>
            </Grid>
            <Grid
              item xs={18}>
              <InfoTabs description={products.description} specs={products.specs} title={products.title} />
            </Grid>
          </Grid>
        </Box>
        <ContactForm />
        <Footer />
        <ScrollTop />
      </Stack>
    </Box>
  );
}