export interface QNAtext {
    title: string;
    questions: Question[];
}

export type Question =  {
    question: string;
    answer: string;
}

export const serviceBlindsText = {
    title: "Žalúzie,\n siete proti hmyzu",
    description: [
       "Oprava a výmena žalúzií",
       "Montáž nových žalúzií",
        "Vonkajšia tieniaca technika",
        "Sieťky proti hmyzu"

    ],
    more_info: 
        `Rýchla a profesionálna oprava vnútorných a vonkajších žalúzií.
        Komponenty máme vždy skladom pre rýchlu opravu Vašich žalúzii
        Nové žalúzie inštalujeme podľa Vášho výberu, s rôznymi možnosťami materiálov a farieb.`
}