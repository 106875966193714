interface BlindsMoreTexts {
    title: string;
    bgImage: string;
    galleryImages: string[];
    description: string[];
    advantages: string[];
}

export const AllBlindsTexts: { [key: string]: BlindsMoreTexts[] } = {
    Interierove_zaluzie: [{
        galleryImages: ["blinds.webp"],
        bgImage: "/pictures/bg3.webp",
        title: "INTERIÉROVÉ ŽALÚZIE",
        description: [
            `Ponúkame interiérové žalúzie ISSO R retiazkové žalúzie, interiérové ISSO RC celotieniace žalúzie, 
            interiérové ISSO BRZDA žalúzie, medzisklenné žalúzie, ale aj atypické žalúzie.`
        ],
        advantages: [
            "Lamela vyrobená z hliníka neprepúšťa, ale odráža slnečné lúče",
            "Široká škála použitia na všetky okná (plast, drevo aj hliník)",
            "Široká paleta farieb lamiel",
            "Možnosť farebne prispôsobiť rám žalúzie k rámu okna",
            "Možnosť vyhotovenia atypického prevedenia pre použitie v trojuholníkových oknách (len pri variante ISSO BRZDA)"
        ]
    }],

    Vertikalne_latkove_zaluzie: [{
        galleryImages: ["vertical_blinds.webp"],
        bgImage: "/pictures/bg3.webp",
        title: "VERTIKÁLNE LÁTKOVÉ ŽALÚZIE",
        description: [
            `Vertikálne žalúzie už dávno nie sú len výsadou „kancelárií“. Vďaka novým moderným látkam zútulnia
            a ochránia pred svetlom aj vašu obývačku alebo spálňu. S obľubou sú využívané aj na zatienenie
            horúcich balkónov.`
        ],
        advantages: [
            "Široký výber látok, rôzne farby a hrúbky látok",
            "Moderné látky v imitácii dreva alebo moderných farebných vzorov",
            "Vertikálne aj atypické šikmé prevedenie",
            "Použitie v interiéri aj na zasklených balkónoch",
            "Možnosť do budúcna jednoducho vymeniť iba látku a zanechať pôvodný rám",
            "V exponovaných miestnostiach ideálne riešenie v kombinácii s interiérovými žalúziami pre dokonalé tienenie",
            "Prevedenie 128mm aj 89mm pre vybrané typy látok do užších priestorov"
        ]
    }],

    Rolety_noc_den: [{
        galleryImages: ["day_night1.webp", "day_night2.webp"],
        bgImage: "/pictures/bg3.webp",
        title: "ROLETY DEŇ A NOC",
        description: [
            `Látkové roletky Deň a Noc patria k designovej špičke tieniacej techniky. Pokiaľ vám ide o kombináciu
                funkčnosti a designu, lepšiu voľbu jednoducho nenájdete.`,
            "Sú vyrobené na mieru vášho okna, čo zaručuje, že nič nezavadzia a nedochádza k hluchým miestam. Dizajnový navíjací bubon umožňuje jednoduchú údržbu, zatiaľ čo bočné prísklenné lišty zabezpečujú čistý dizajn a maximalizujú funkcionalitu. Vďaka jednoduchému ovládaniu a možnosti voľby medzi krytým a otvoreným bubnom sa naše rolety Deň a Noc stanú výrazným dizajnovým prvkom, ktorý zjednotí priestor a dodá mu čisté línie."
        ],
        advantages: [
            "Moderný vzhľad",
            "Veľký výber látok",
            "Bočné prísklenné lišty pre čistý dizajn a maximalizáciu funkcionality",
            "Prevedenie presne na mieru",
            "Antistatická látka odpudzujúca prach",
            "Jednoduchosť ovládania",
            "Zjednocuje priestor",
            "V polohe Deň nahrádza záclonu a vytvára intimitu prostredia",
            "Veľmi dobré tienenie"
        ]
    }],

    Rolety_na_stresne_okna: [{
        galleryImages: ["roof-blinds.gif"],
        bgImage: "/pictures/bg3.webp",
        title: "ROLETY NA STREŠNÉ OKNÁ",
        description: [
            `Na mieru vášho strešného okna.`,
            "Dodávame pre drevené strešné okná VELUX / FAKRO. Vďaka precíznemu spracovaniu a kvalitným materiálom naše rolety poskytujú vynikajúcu reguláciu svetla a tepla, čím zlepšujú komfort vášho podkrovného priestoru."
        ],
        advantages: [
            "Veľký výber látok pre každý interiér",
            `Špeciálne tieniace látky sú pogumované s reflexnou striebornou vrstvou smerom k exteriéru
    a príjemným vzhľadom látky smerom do interiéru pre maximálne zníženie prehrievania
    interiéru počas teplých letných dní`,
            "Bočné krycie lišty pre lepšie tienenie",
            "Výroba na mieru vášho okna"
        ]
    }],

    Vonkajsie_zaluzie: [{
        galleryImages: ["vertical.webp"],
        bgImage: "/pictures/bg3.webp",
        title: "VONKAJŠIE HLINÍKOVÉ ŽALÚZIE",
        description: [
            `Špička v kombinácii dizajnu a funkcionality.`,
            `Tieto žalúzie poskytujú ideálne tienenie, ktoré zachytáva slnečné lúče ešte pred vaším oknom, dokonale ochráni, pri
            zavretých a kvalitných oknách dokáže udržať teplotu aj o 15 až 20 °C nižšiu ako v exteriéri
            pri dostatočnom prepúšťaní žiaduceho svetla.`
        ],
        advantages: [
            "Zníži náklady na bývanie",
            "Zlepší vzhľad fasády a zatraktívni bývanie",
            "Široká paleta farieb už v štandardnej cene",
            "Výber profilov Z90, Z70, C a S profily rôznych šírok",
            "Ručný aj motorický pohon",
            "Možnosť diaľkového ovládania",
            "Možnosť smart funkcií",
            "Ideálne pre novostavby",
            "Možnosť montovať aj v existujúcich stavbách"
        ]
    }],

    Hlinikove_Plastove_vonkajsie_rolety: [{
        galleryImages: ["plastic.webp"],
        bgImage: "/pictures/bg3.webp",
        title: "HLINÍKOVÉ VONKAJŠIE ROLETY",
        description: [
            `Naše hliníkové vonkajšie rolety ponúkajú dokonalú kombináciu štýlu a ochrany. Vyrobené z kvalitného hliníka, tieto rolety poskytujú vynikajúcu tepelnú a zvukovú izoláciu, pričom chránia vaše okná pred nepriaznivým počasím a zvyšujú bezpečnosť vášho domova. S jednoduchým ovládaním a moderným dizajnom sú naše vonkajšie rolety ideálnym riešením pre každý typ exteriéru.`
        ],
        advantages: [
            "Vysoká odolnosť a pevnosť",
            "Vysoká farebná stálosť a dlhá životnosť",
            "Výber z viacerých farieb",
            "Vynikajúca ochrana pred teplom, nakoľko slnečné lúče zastaví ešte pred vaším oknom",
            "Možnosť ručného aj motorického pohonu",
            "Možnosť diaľkového ovládania",
            "Spojenie dizajnu a funkčnosti"
        ]
    },
    {
        galleryImages: ["plastic.webp"],
        bgImage: "/pictures/bg3.webp",
        title: "PLASTOVÉ VONKAJŠIE ROLETY",
        description: [
            `Plastové rolety boli v minulosti hojne využívané na krytie obytných domov pred prílišným teplom.`,
            `Dnes sú pre farebnú stálosť na tento účel používané hliníkové vonkajšie rolety. Plastové rolety
            používame najmä v interiéroch, napr. na kryty servisných dvierok, provizórne delenie miestností,
            kumbálov a podobne. Ak ale máte použité plastové rolety v exteriéri a potrebujete ich vymeniť,
            neváhajte nás kontaktovať.`
        ],
        advantages: [
            "Veľký výber farieb vrátane transparentnej",
            "Krycí bubon súčasťou dodávky",
            "Možnosť ovládať z exteriéru alebo z interiéru (potrebné zvoliť požadovaný variant pri zadaní do výroby)"
        ]
    }]
}

