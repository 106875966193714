export const serviceMoreText = {
    title: "Ďalšie služby",
    description: [
        "Tepelné izolácie",
        "Bezpečnostné prvky",
        "Renovácia okenných rámov"
    ],
    more_info:
        `Zlepšenie tepelnej izolácie okien a dverí pomocou špeciálnych fólií a izolácií.
        Inštalácia bezpečnostných fólií, zámkov a poistiek pre zvýšenie bezpečnosti.
        Obnova a oprava okenných rámov vrátane maľovania a impregnácie.`

};

export const windowServices = {
    Forging: {
        title: "Oprava a výmena kovania",
        more_info:
            `Podľa potreby zabezpečujeme opravu na všetky typy kovaní. Treba si však uvedomiť, že všetko má svoj dôvod. Aj kovanie sa nepokazilo samo od seba. Väčšinou je to dôsledkom zanedbania pravidelnej údržby Vašich okien. Preto ich po výmene kovania aj správne nastavíme, prípadne hľadáme aj ďalšie pravdepodobné príčiny poruchy a navrhneme vhodné riešenie. Využite naše skúsenosti. Len výmenou poškodeného dielu za nový zvyčajne nič dlhodobo nevyriešite.`
    },
    StuckWindows: {
        title: "Zaseknuté okná a dvere",
        more_info:
            `Ak neviete otvoriť okno alebo dvere, pravdepodobne prestala fungovať nejaká časť kovania. Vaše okno vieme bezpečne bez poškodenia otvoriť a zabezpečiť výmenu kovania.

V prípade akútneho problému nás neváhajte kontaktovať aj mimo pracovných hodín.`
    },
    Winter_Summer: {
        title: "Nastavenie zimného a letného režimu",
        more_info:
            `Každé okno je od výroby vybavené excentrickým zatváraním. Nastavenie letného režimu umožní ľahší chod kľučky pri menšej tesnosti okna, čo uľahčí jeho používanie, naopak na zimu sa dá tento režim zmeniť. Je to relatívne jednoduchý úkon. Preto je aj cena za túto službu symbolická a to 3 Eurá za okenné krídlo + výjazd podľa lokality.

POZOR, netreba si zamieňať toto nastavenie s kompletným nastavením okna, kde sa ladí aj poloha krídla voči rámu, kontroluje resp. upravuje osadenie zatváracích bodov, kontroluje funkčnosť jednotlivých komponentov kovania. V praxi sa ukazuje, že si tieto nastavenia ľudia medzi sebou mýlia. Podľa akýchsi „overených rád“ si nastavia okná a výsledok je častokrát horší ako keby neurobili nič.`,
    },
    RegularService: {
        title: "Pravidelná údržba",
        more_info:
            `O vaše okná sa budeme starať v pravidelných intervaloch. Na túto službu Vám taktiež pripravíme zvýhodnenú ponuku.
Kontaktujte nás ohľadom pravidelného servisu Vašich okien ešte dnes! Je to najlepšia voľba pre Vaše okná.`
    },
    Sealing: {
        title: "Výmena tesnení",
        more_info:
            `Interval výmeny tesnenia je raz za cca 10 rokov. Interval závisí od exponovanosti strany, na ktorej sú okná (extrémne slnko znehodnotí tesnenie aj za kratšiu dobu) a zloženia tesnenia. Spravidla menia životnosť tesnenia najmä bieliace prísady, ktoré sa pridávajú do gúm. Dlhšiu životnosť má teda čierne tesnenie.

Rozhoduje kvalita. Používame originálne tesnenia na daný profil okna. Ešte dôležitejšie ako samotné tesnenie je spôsob, akým je tesnenie vymenené.`
    },
};
