// FooterCopyright.tsx
import Typography from '@mui/material/Typography';
import React from 'react';

interface FooterCopyrightProps {
  year: number;
}

const FooterCopyright: React.FC<FooterCopyrightProps> = ({ year }) => {
  return (
    <Typography pt={5} variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {year}
      {' Alvipstav s.r.o.. Všetky práva vyhradené.'}
    </Typography>
  );
};

export default FooterCopyright;