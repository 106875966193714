import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { landingNetsText } from "../texts/landingText";
import { qnaBlindsText } from "../texts/qnaText";
import NetsServicesGrid from "./grids/NetsServicesGrid";
import ContactForm from "./main/contactForm";
import Footer from "./main/footer";
import NavBar from "./main/nav-bar";
import { LandingModel } from "./models/landingModel";
import { QnaModel } from "./models/qnaSectionModel";
import ScrollTop from "./models/ScrollToTop";



export default function netsPage() {
    const location = useLocation();
    const targetId = location.state?.targetId;

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
      
  }, [targetId]);
    return (
      <Box sx={{ width: '100%',  position: 'realtive', backgroundColor: 'primary.main'}}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          display='flex'
        >
          <NavBar/>
          <LandingModel texts={landingNetsText} component={NetsServicesGrid}/>
          <QnaModel texts={qnaBlindsText} />
          <ContactForm />        
          <Footer/>
          <ScrollTop />       
        </Stack>
      </Box>
    );
  }