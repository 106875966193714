import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { TechnicalSpec } from '../../texts/technicalSpecsText';
import { ProductTable } from './productTable';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface InfoTabsProps {
    description: string;
    specs: TechnicalSpec[];
    title: string;
}



export default function InfoTabs({ description, specs, title }: InfoTabsProps) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={value}
                centered
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
            >
                <Tab sx={{
                    fontSize: {
                        xs: 'subtitle1.fontSize',
                        sm: 'h6.fontSize',
                        md: 'h6.fontSize',
                        lg: 'h6.fontSize',
                    },
                }} label="Popis" {...a11yProps(0)} />
                <Tab sx={{
                    fontSize: {
                        xs: 'subtitle1.fontSize',
                        sm: 'h6.fontSize',
                        md: 'h6.fontSize',
                        lg: 'h6.fontSize',
                    },
                }} label="Technické parametre" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0} >
                <Typography  sx={{ 
                    whiteSpace: "pre-wrap",
                    fontSize: {
                        xs: 'subtitle1.fontSize',
                        sm: 'h6.fontSize',
                        md: 'h6.fontSize',
                        lg: 'h6.fontSize',
                    },color: "primary.dark", 
                    boxShadow: 5, p: {xs:2, sm:5}, borderRadius: {xs:0, md:2}, backgroundColor: "background.default"
                    }}>{description}</Typography>
            </TabPanel>
            <TabPanel value={value} index={1} >
                <Box sx={{ p:0, backgroundColor:"paper"}}>
                <ProductTable props={specs} title={title}/>
                * uvedené hodnoty sú maximálne, ktoré sa získali pri skúškach.
                </Box>
            </TabPanel>
        </Box>
    );
}
