export const serviceWindowText = {
    title: "Servis okien",
    description: [
        "Oprava a výmena kovania",
        "Zaseknuté okná a dvere",
        "Nastavenie zimného/letného režimu",
        "Pravidelná údržba",
        "Výmena tesnení",
    ],
    more_info: 
        `Ponúkame rýchlu opravu alebo výmenu kovaní pre plastové, drevené a hliníkové okná. Správne nastavenie tesnosti okien podľa sezóny pre lepšiu energetickú účinnosť zaručené.
        Pre vaše okná ponúkame aj obnovu tesnenia pre dokonalú izoláciu, vykonávanú každých 10 rokov.
        Nefunkčné kovanie odborne otvoríme a vymeníme.
        Taktiež vykonávame kompletné nastavenie, premazanie kovania a impregnáciu tesnenia každé dva roky.`
}

export const BlindsPoints = {
    interier: {
        description: [
            "interiérové ISSO R retiazkové žalúzie",
            "interiérové ISSO RC celotieniace žalúzie",
            "interiérové ISSO BRZDA žalúzie",
            "medzisklenné žalúzie",
            "atypické žalúzie"
        ]
    },
    vertical: {
        description: [
            "široký výber látok",
            "rôzne farby a hrúbky látok",
            "vertikálne aj atypické šikmé prevedenie",
            "prevedenie 128mm aj 89mm pre vybrané typy látok",
            "použitie v interiéri aj na zasklených balkónoch"
        ]
    },
    day_night: {
        description: [
            "široký výber látok",
            "rôzne farby a hrúbky látok",
            "jednoduchosť ovládania",
            "veľmi dobré tienenie",
            "prevedenie presne na mieru"
        ]
    },
    roof: {
        description: [
            "veľký výber látok pre každý interiér",
            "bočné krycie lišty pre lepšie tienenie",
            "výroba na mieru vášho okna",
            "látky s reflexnou striebornou vrstvou smerom k exteriéru pre maximálne zníženie prehrievania",
        ]
    },
    alumulemu: {
        description: [
            "vysoká odolnosť a pevnosť",
            "výber z viacerých farieb",
            "vynikajúca ochrana pred teplom",
            "možnosť ručného aj motorického pohonu",
            "možnosť diaľkového ovládania"
        ]
    },
    outdoor: {
        description: [
            "zníži náklady na bývanie",
            "zlepší vzhľad fasády",
            "široká paleta farieb",
            "ručný aj motorický pohon",
            "možnosť smart funkcií"
        ]
    }
}

export const NetsPoints = {
    window: {
        description: [
            "široká paleta farieb",
            "imitácie dreva",
            "možnosť individuálneho striekania rámov podľa zadania RAL",
        ]
    },
    door: {
        description: [
            "široká paleta farieb",
            "imitácia dreva",
            "individuálny nástrek v odtieňoch RAL",
            "montujeme na všetky typy dverí",               
        ]
    }
}
