// Footer.tsx
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import FooterCopyright from './Copyright';
import { footerTexts } from '../../texts/footerText';

interface FooterProps {
  // Add any props you need to pass to the Footer component
}

const Footer: React.FC<FooterProps> = () => {
    const currentYear = new Date().getFullYear();
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'primary.main',
        width: '100%',
        color: 'white',
        py: 2,
        px: 4,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} xs={24} justifyContent={"center"}>
          <Grid item xs={12} sm={4}>
            <Typography id="about-us" variant="h6">{footerTexts.aboutUs.title}</Typography>
            <Typography variant="body2">
            {footerTexts.aboutUs.description}
            </Typography>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">{footerTexts.contact.title}</Typography>
            {footerTexts.contact.items.map((item, index) => (
              <Typography key={index} variant="body2">
                {item}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Container>
      <FooterCopyright year={currentYear} />
    </Box>
  );
};

export default Footer;