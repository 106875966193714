interface IGrid {
    title: string;
    description: string[];
    link: string;

}

export const Ideal4000GridText: IGrid = {
    title: "Ideal 4000",
    description: [
        "5-komorový profil",
        "dvojité tesnenie",
        "termoizolačné dvojsklo",
        "celoobvodové kovanie",
        "veľká paleta farieb",
    ],
    link: "/Ideal4000"
}

export const Ideal5000GridText: IGrid = {
    title: "Ideal 5000",
    description: [
        "5-komorový profil",
        "trojité tesnenie",
        "termoizolačné dvojsklo",
        "celoobvodové kovanie",
        "veľká paleta farieb",
    ],
    link: "/Ideal5000"
}

export const Ideal7000GridText: IGrid = {
    title: "Ideal 7000",
    description: [
        "6-komorový profil",
        "dvojité tesnenie",
        "termoizolačné trojsklo",
        "celoobvodové kovanie",
        "veľká paleta farieb",
    ],
    link: "/Ideal7000"
}

export const Ideal8000GridText: IGrid = {
    title: "Ideal 8000",
    description: [
        "6-komorový profil",
        "trojité tesnenie",
        "termoizolačné trojsklo",
        "celoobvodové kovanie",
        "veľká paleta farieb",
    ],
    link: "/Ideal8000"
}
