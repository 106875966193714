
export interface TechnicalSpec {
    name: string;
    value: string;
}


export type TechnicalSpecsProps = {
    title: string;
    name: string;
    description: string;
    specs: TechnicalSpec[];
    images: string[];

}



export const ProductsTexts: { [key: string]: TechnicalSpecsProps } = {
    Ideal4000: {
name: "Ideal 4000",
images: [
    "/ideal4000/140x01_140x20MF_120880.webp",
    "/ideal4000/140x01_229029_140x20_259021.webp",
    "/ideal4000/140x01-65mm_140x27czarne.webp",
    "/ideal4000/140x01-65mm_140x27szare.webp",
    "/ideal4000/140000_140022_120849.webp",
    "/ideal4000/140000_140022_120850 bg less.webp",
],
title: "Technické špecifikácie - IDEAL 4000",
description: `Plastové okná vyrobené z okenných profilov Aluplast Ideal 4000 sú na Slovensku veľmi populárne. 
5-komorový profil so stavebnou hĺbkou 70 mm, vybavený termoizolačným dvojsklom, ponúka ideálny pomer cena/kvalita. 
Nájde svoje uplatnenie najmä pri rekonštrukciách. Pri vyšších termoizolačných nárokoch ich je možné zaskliť aj 
termoizolačným trojsklom alebo PUR panelovou výplňou do celkovej hrúbky 36 mm. Stavebná hĺbka v kombinácii 
systému Aluplast Ideal 4000 new je až 85 mm.

Ponúkame ich v širokej palete farieb. Je možné kombinovať farebné odtiene s bielou zvlášť pre exteriér 
alebo interiér. Táto voľba je vhodná pre tých, ktorí chcú zachovať štandardný vonkajší vzhľad fasády 
na bytovke a interiér si chcú prispôsobiť podľa vlastnej voľby.

Vďaka vysokej pevnosti a oceľou vystuženým profilom značkových okien Aluplast Ideal 4000 budú pre Vás 
dobrou voľbou a odmenia sa Vám vysokou životnosťou a úsporou pri vykurovaní.`,
specs: [
    { name: 'Stavebná hĺbka', value: '70-85 mm' },
    { name: 'Šírka zostavy rámu / krídla', value: 'od 102 mm' },
    { name: 'Maximálna hrúbka zasklenia', value: '36 mm' },
    { name: 'Počet komôr', value: '5-6' },
    { name: 'Systém tesnenia', value: '2 tesnenia' },
    { name: 'Systém posilnenia', value: 'oceľ' },
    { name: 'Dostupný štýl profilov', value: 'classic-line, round-line' },
    { name: 'Súčiniteľ prechodov tepla', value: 'Uf=1,3 - 1,4 W/m²K' },
    { name: 'Odolnosť vetra', value: 'C4*' },
    { name: 'Vodeodolnosť', value: '9A*' },
    { name: 'Priepustnosť vetra', value: '4*' },
    { name: 'Zvuková izolácia 4/16/4', value: '34(-2;-5) dB' },
    { name: 'Maximálna akustická izolácia', value: '45(-1;-3) dB*' },
    { name: 'Technológia bonding inside', value: 'áno' },
    { name: 'Možnosť peniť', value: 'áno' },
    { name: 'Vymeniteľné tesnenie', value: 'áno' },
]
    },
    Ideal5000: {
name: "Ideal 5000",
images: [
    "/Ideal5000/ideal5000 (1).webp",
    "/Ideal5000/ideal5000 (2).webp",
    "/Ideal5000/ideal5000 (3).webp",
    "/Ideal5000/ideal5000 (4).webp",
],
title: "Technické špecifikácie - IDEAL 5000",
description: `Plastové okná vyrobené z okenných profilov Aluplast Ideal 5000 sú vylepšenou verziou Idealu 4000. 
5-komorový profil so stavebnou hĺbkou 70 mm je doplnený o stredové tesnenie. S celkovým počtom 
3 tesnení ponúka vyššiu odolnosť voči vetru a zároveň predeluje vnútorný priestor medzi krídlom 
a rámom na 2 komory, čo zlepšuje tepelnú izoláciu celého okna a dodatočne chráni okenné kovanie. 
V prevedení classic line aj round line (rovná / oblá hrana). 
V štandarde zasklené termoizolačným dvojsklom s možnosťou zasklenia termoizolačným trojsklom do 
hrúbky 42 mm.

Ponúkame ich v širokej palete farieb. Je možné kombinovať farebné odtiene s bielou zvlášť pre 
exteriér alebo interiér. Táto voľba je vhodná pre tých, ktorí chcú zachovať štandardný vonkajší vzhľad 
fasády na bytovke a interiér si chcú prispôsobiť podľa vlastnej voľby.

Vďaka vysokej pevnosti a oceľou vystuženým profilom značkových okien Aluplast Ideal 5000 budú pre Vás 
dobrou voľbou a odmenia sa Vám vysokou životnosťou a úsporou pri vykurovaní.`,
specs: [
    { name: 'Stavebná hĺbka', value: '70-85 mm' },
    { name: 'Šírka zostavy rámu / krídla', value: 'od 102 mm' },
    { name: 'Maximálna hrúbka zasklenia', value: '36 mm' },
    { name: 'Počet komôr', value: '5-6' },
    { name: 'Systém tesnenia', value: '3 tesnenia' },
    { name: 'Systém posilnenia', value: 'oceľ' },
    { name: 'Dostupný štýl profilov', value: 'classic-line, round-line' },
    { name: 'Súčiniteľ prechodov tepla', value: 'Uf=1,3 - 1,4 W/m²K' },
    { name: 'Odolnosť vetra', value: 'C4*' },
    { name: 'Vodeodolnosť', value: '9A*' },
    { name: 'Priepustnosť vetra', value: '4*' },
    { name: 'Zvuková izolácia 4/16/4', value: '34(-2;-5) dB' },
    { name: 'Maximálna akustická izolácia', value: '45(-1;-3) dB*' },
    { name: 'Technológia bonding inside', value: 'áno' },
    { name: 'Možnosť peniť', value: 'áno' },
    { name: 'Vymeniteľné tesnenie', value: 'áno' },
]
    },
    Ideal7000: {
name: "Ideal 7000",
images: [
    "/Ideal7000/ideal7000 (1).webp",
    "/Ideal7000/ideal7000 (2).webp",
    "/Ideal7000/ideal7000 (3).webp",
],
title: "Technické špecifikácie - IDEAL 7000",
description: `Okná zo systému Aluplast Ideal 7000 ponúkajú 6-komorový profil zasklenný termoizolačným 
trojsklom hrúbky 48 mm v štandarde. Vďaka tomu získate cenovo dostupné okná vhodné aj do 
novostavieb. Hrubá konštrukcia plastových profilov so stavebnou hĺbkou 85 mm, vystužená uzavretou 
oceľovou výstuhou, ponúka vysokú pevnosť aj pre konštrukcie okien s väčšími rozmermi. Okná sú 
opatrené dvojitým tesnením.

Ponúkame ich v širokej palete farieb. Je možné kombinovať farebné odtiene s bielou zvlášť pre 
exteriér alebo interiér. Táto voľba je vhodná pre tých, ktorí chcú zachovať štandardný vonkajší vzhľad 
fasády na bytovke a interiér si chcú prispôsobiť podľa vlastnej voľby.

Vďaka vysokej pevnosti a oceľou vystuženým profilom značkových okien Aluplast Ideal 7000 budú pre Vás 
dobrou voľbou a odmenia sa Vám vysokou životnosťou a úsporou pri vykurovaní.`,
specs: [
    { name: 'Stavebná hĺbka', value: '85 mm' },
    { name: 'Šírka zostavy rámu / krídla', value: 'od 102 mm' },
    { name: 'Maximálna hrúbka zasklenia', value: '48 mm' },
    { name: 'Počet komôr', value: '6' },
    { name: 'Systém tesnenia', value: '3 tesnenia' },
    { name: 'Systém posilnenia', value: 'oceľ' },
    { name: 'Dostupný štýl profilov', value: 'classic-line, round-line' },
    { name: 'Súčiniteľ prechodov tepla', value: 'Uf=1,3 - 1,4 W/m²K' },
    { name: 'Odolnosť vetra', value: 'C4*' },
    { name: 'Vodeodolnosť', value: '9A*' },
    { name: 'Priepustnosť vetra', value: '4*' },
    { name: 'Zvuková izolácia 4/16/4', value: '34(-2;-5) dB' },
    { name: 'Maximálna akustická izolácia', value: '45(-1;-3) dB*' },
    { name: 'Technológia bonding inside', value: 'áno' },
    { name: 'Možnosť peniť', value: 'áno' },
    { name: 'Vymeniteľné tesnenie', value: 'áno' },
]
    },
    Ideal8000: {
name: "Ideal 8000",
images: [
    "/Ideal8000/ideal8000 (1).webp",
    "/Ideal8000/ideal8000 (2).webp",
    "/Ideal8000/ideal8000 (3).webp",
    "/Ideal8000/ideal8000 (4).webp",
    "/Ideal8000/ideal8000 (5).webp",
],
title: "Technické špecifikácie - IDEAL 8000",
description: `Plastové okná vyrobené z okenných profilov Aluplast Ideal 8000 sú dnes špičkou v oblasti okien.

6-komorový plastový profil stavebnej hĺbky 85 mm s 3 tesneniami a termoizolačným trojsklom 
v celkovej hrúbke 48 mm s teplým rámikom vám ponúka ideálne termoizolačné a akustické 
vlastnosti, vysokú pevnosť a dlhú životnosť. Konštrukcia profilu, pri ktorej dosadá tretie 
(stredové) tesnenie na rám okna, ponúka maximálnu odolnosť voči pôsobeniu tlaku vetra a 
zároveň chráni vaše kovanie pred koróziou.

Ponúka sa najmä do novostavieb a nízkoenergeticky náročných až pasívnych domov. Svoje uplatnenie 
nájde určite aj pri rekonštrukciách, ak sa chcete vyhnúť akýmkoľvek kompromisom. Jednoducho 
to najlepšie, čo ponúka trh.`,
specs: [
    { name: 'Stavebná hĺbka', value: '85 mm' },
    { name: 'Šírka zostavy rámu / krídla', value: 'od 102 mm' },
    { name: 'Maximálna hrúbka zasklenia', value: '48 mm' },
    { name: 'Počet komôr', value: '6' },
    { name: 'Systém tesnenia', value: '3 tesnenia' },
    { name: 'Systém posilnenia', value: 'oceľ' },
    { name: 'Dostupný štýl profilov', value: 'classic-line, round-line' },
    { name: 'Súčiniteľ prechodov tepla', value: 'Uf=1,3 - 1,4 W/m²K' },
    { name: 'Odolnosť vetra', value: 'C4*' },
    { name: 'Vodeodolnosť', value: '9A*' },
    { name: 'Priepustnosť vetra', value: '4*' },
    { name: 'Zvuková izolácia 4/16/4', value: '34(-2;-5) dB' },
    { name: 'Maximálna akustická izolácia', value: '45(-1;-3) dB*' },
    { name: 'Technológia bonding inside', value: 'áno' },
    { name: 'Možnosť peniť', value: 'áno' },
    { name: 'Vymeniteľné tesnenie', value: 'áno' },
]
    },
};
