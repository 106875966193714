export type LandingText =  {
    title: string;
    subtitle: string;
    description?: string;
    button: string;
    bgImage: string;
}

export const landingText: LandingText = {
    title: "SERVIS OKIEN A DVERÍ",
    subtitle: "BEZPLATNÁ OBHLIADKA A PORADENSTVO",
    description: "Pokazilo sa vám okno, neviete zatvoriť vchodové dvere, nefunguje vám žalúzia alebo potrebujete vymeniť parapet? Ponúkame rýchle a efektívne riešenia pre všetky vaše problémy. Kontaktujte nás a my sa o všetko postaráme!",
    button: "Kontaktujte nás",
    bgImage: "/pictures/bg1.webp"
};

export const landingBlindsText: LandingText = {
    title: "TIENIACA TECHNIKA",
    subtitle: "BEZPLATNÁ OBHLIADKA A PORADENSTVO",
    description: "V našej ponuke nájdete široký výber tieniacej techniky pre vaše okná. Kontaktujte nás a my sa o všetko postaráme!",
    button: "Kontaktujte nás",
    bgImage: "/pictures/bg2.webp"
};

export const landingNetsText: LandingText = {
    title: "SIETE PROTI HMYZU",
    subtitle: "BEZPLATNÁ OBHLIADKA A PORADENSTVO",
    description: "V našej ponuke nájdete široký výber sieťok proti hmyzu pre vaše okná. Kontaktujte nás a my sa o všetko postaráme!",
    button: "Kontaktujte nás",
    bgImage: "/pictures/top_bg.webp"
};

export const landingWindowsText: LandingText = {
    title: "PLASTOVÉ OKNÁ",
    subtitle: `DODANIE, MONTÁŽ
A SERVIS OKIEN`,
    description: "V našej ponuke nájdete široký výber plastových okien. Kontaktujte nás a my sa o všetko postaráme!",
    button: "Kontaktujte nás",
    bgImage: "/pictures/windowsill_details2.webp"
};
