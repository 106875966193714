import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import ContactForm from "./main/contactForm";
import Footer from "./main/footer";
import NavBar from "./main/nav-bar";
import QnaSection from "./main/QnaSection";
import ScrollTop from "./models/ScrollToTop";
import WorkGallery from "./main/workGallery";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import LandingPageHeader from "./main/landing-top";
import { landingBlindsText } from "../texts/landingText";
import { LandingModel } from "./models/landingModel";
import BlindsServicesGrid from "./grids/BlindsServicesGrid";
import { QnaModel } from "./models/qnaSectionModel";
import { qnaBlindsText } from "../texts/qnaText";



export default function blindsPage() {
    const location = useLocation();
    const targetId = location.state?.targetId;
    const isFirstRender = useRef(true);

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
      
  }, [targetId]);
    return (
      <Box sx={{ width: '100%',  position: 'realtive', backgroundColor: 'primary.main'}}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          display='flex'
        >
          <NavBar/>
          <LandingModel texts={landingBlindsText} component={BlindsServicesGrid}/>
          <QnaModel texts={qnaBlindsText} />
          <ContactForm />        
          <Footer/>
          <ScrollTop />       
        </Stack>
      </Box>
    );
  }