import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';


const ScrollTop: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Zoom in={visible} >
      <Fab color='secondary' size='medium'
        onClick={handleClick}
      sx={{
        position: 'fixed',
        backgroundColor: 'primary.dark',
        bottom: 25,
        right: 25,
      }}>
        <KeyboardArrowUpIcon sx={{color: 'secondary.light'}}/>
      </Fab>
    </Zoom>
  );
};

export default ScrollTop;
