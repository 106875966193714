import React from "react";
import { serviceBlindsText } from "../../texts/serviceBlindsText";
import { serviceEmergencyText } from "../../texts/serviceEmergencyText";
import { serviceMoreText, windowServices } from "../../texts/serviceMoreText";
import { serviceWindowsillText } from "../../texts/serviceWindowsillText";
import { serviceWindowText } from "../../texts/serviceWindowText";
import { ServiceDetails } from "../models/details";
import { Forging } from "./forging";

export function WindowsDetails(){
    return(
        <ServiceDetails title={serviceWindowText.title} content={serviceWindowText.more_info} background="/pictures/window_repair.webp"/>
    );
}
export function BlindsDetails(){
    return(
        <ServiceDetails title={serviceBlindsText.title} content={serviceBlindsText.more_info} background="/pictures/window_blinds.webp"/>
    );
}
export function WindowsillDetails(){
    return(
        <ServiceDetails title={serviceWindowsillText.title} content={serviceWindowsillText.more_info} background="/pictures/windowsill_details2.webp"/>
    );
}
export function EmergencyDetails(){
    return(
        <ServiceDetails title={serviceEmergencyText.title} content={serviceEmergencyText.more_info} background="/pictures/window_damaged.webp"/>
    );
}
export function MoreServicesDetails(){
    return(
        <ServiceDetails title={serviceMoreText.title} content={serviceMoreText.more_info} background="/pictures/more_services_bg.webp"/>
    );
}

export function ForgingCmp(){
    return(
        <Forging title={windowServices["Forging"].title} content={windowServices["Forging"].more_info} background="/pictures/more_services_bg.webp"/>
    );
}
export function Winter_Summer(){
    return(
        <ServiceDetails title={windowServices["Winter_Summer"].title} content={windowServices["Winter_Summer"].more_info} background="/pictures/more_services_bg.webp"/>
    );
}
export function StuckWindows(){
    return(
        <ServiceDetails title={windowServices["StuckWindows"].title} content={windowServices["StuckWindows"].more_info} background="/pictures/more_services_bg.webp"/>
    );
}
export function RegularService(){
    return(
        <ServiceDetails title={windowServices["RegularService"].title} content={windowServices["RegularService"].more_info} background="/pictures/more_services_bg.webp"/>
    );
}
export function Sealing(){
    return(
        <ServiceDetails title={windowServices["Sealing"].title} content={windowServices["Sealing"].more_info} background="/pictures/more_services_bg.webp"/>
    );
}
