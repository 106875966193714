import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { baseurl } from '../../router';
import { Ideal4000GridText, Ideal5000GridText, Ideal7000GridText, Ideal8000GridText } from '../../texts/products/windowsProductsTexts';
import ServiceCard from '../models/ServiceCard';




export default function WindowProductsServicesGrids() {
    const navigate = useNavigate();
    return (
        <Box sx={{ width: '100%', pt: 10, p: { xs: 2, sm: 5,},  maxWidth:"1980px" }} id="services">
            <Grid
                container rowSpacing={1}
                display={"flex"}
                justifyContent="center" // Center horizontally in the container
                rowGap={{ xs: 1, sm: 2, md: 3 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                columns={{ xs: 2, sm: 6, lg:12, xl: 18 }}
                sx={{
                    ml: { xl: 5, lg: 15, md: 5 },
                    mr: { xl: 5, lg: 15, md: 5 },
                }}>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/plastove-okna"+Ideal4000GridText.link); }}
                        description={Ideal4000GridText.description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/ideal4000/140x01_140x20MF_120880.webp"}
                        title={Ideal4000GridText.title} />
                </Grid>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/plastove-okna"+Ideal5000GridText.link); }}
                        description={Ideal5000GridText.description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/Ideal5000/ideal5000 (1).webp"}
                        title={Ideal5000GridText.title} />
                </Grid>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/plastove-okna"+Ideal7000GridText.link); }}
                        description={Ideal7000GridText.description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/Ideal7000/ideal7000 (1).webp"}
                        title={Ideal7000GridText.title} />
                </Grid>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/plastove-okna"+Ideal8000GridText.link); }}
                        description={Ideal8000GridText.description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/Ideal8000/ideal8000 (2).webp"}
                        title={Ideal8000GridText.title} />
                </Grid>
            </Grid>
        </Box>
    );
}
