import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { MainServices } from '../../texts/mainServicesTexts';
import { ButtonServiceCard } from '../models/ButtonServiceCard';




export default function ServicesGrid() {
    const navigate = useNavigate();


    return (
        <Box sx={{ width: '100%', pt: 10, p: { xs: 2, sm: 5,},  maxWidth:"1980px" }} id="services">
            <Grid
                container rowSpacing={1}
                justifyContent={"center"}
                rowGap={{ xs: 1, sm: 2, md: 3 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                columns={{ xs: 2, sm: 6, lg:12, xl: 18 }}
                sx={{
                    ml: { xl: 5, lg: 15, md: 5 },
                    mr: { xl: 5, lg: 15, md: 5 },
                }}>
                    {MainServices.map((card) => (
                    <Grid xs={6}>
                        <ButtonServiceCard texts={card} />
                </Grid>
            ))}   
            </Grid>
        </Box>
    );
}
