import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { baseurl } from '../../router';
import { BlindsPoints } from '../../texts/serviceWindowText';
import ServiceCard from '../models/ServiceCard';




export default function BlindsServicesGrid() {
    const navigate = useNavigate();
    return (
        <Box sx={{ width: '100%', pt: 10, p: { xs: 2, sm: 5,},  maxWidth:"1980px" }} id="services">
            <Grid
                container rowSpacing={1}
                justifyContent="center" // Center horizontally in the container
                rowGap={{ xs: 1, sm: 2, md: 3 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                columns={{ xs: 2, sm: 6, lg:12, xl: 18 }}
                sx={{
                    ml: { xl: 5, lg: 15, md: 5 },
                    mr: { xl: 5, lg: 15, md: 5 },
                }}>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/tieniaca-technika/Interierove_zaluzie"); }}
                        description={BlindsPoints["interier"].description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/blinds/blinds.webp"}
                        title="Interiérové žalúzie" />
                </Grid>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/tieniaca-technika/Vertikalne_latkove_zaluzie"); }}
                        description={BlindsPoints["vertical"].description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/blinds/vertical_blinds.webp"}
                        title="Vertikálne látkové žalúzie" />
                </Grid>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/tieniaca-technika/Rolety_noc_den"); }}
                        description={BlindsPoints["day_night"].description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/blinds/day_night1.webp"}
                        title="Rolety deň a noc" />
                </Grid>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/tieniaca-technika/Rolety_na_stresne_okna"); }}
                        description={BlindsPoints["roof"].description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/blinds/roof_blinds.webp"}
                        title="Rolety na strešné okná" />
                </Grid>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/tieniaca-technika/Hlinikove_Plastove_vonkajsie_rolety"); }}
                        description={BlindsPoints["alumulemu"].description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/blinds/plastic.webp"}
                        title="Hliníkové a Plastové vonkajšie rolety" />
                </Grid>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/tieniaca-technika/Vonkajsie_zaluzie"); }}
                        description={BlindsPoints["outdoor"].description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/blinds/vertical.webp"}
                        title="Vonkajšie žalúzie" />
                </Grid>
            </Grid>
        </Box>
    );
}
