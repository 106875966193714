import CheckIcon from '@mui/icons-material/CheckCircleTwoTone';
import { Box, List, ListItem, ListItemText, Paper, Slide, Typography } from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import React from "react";
import { useInView } from "react-intersection-observer";

interface ProductListProps {
    items: string[];
}







export const ProductList: React.FC<ProductListProps> = ({ items }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
});
    return (
      
        <Box  ref={ref} sx={{overflow:"hidden"}}>
          <Slide  direction="left" in={inView} timeout={1000} >
        <Paper  elevation={5} sx={{ p:{xs:3, sm:5}, m:{xs:2, sm:5}, backgroundColor:"primary.dark"}}>
            <Typography variant="h4" component="h2" color={"secondary.main"}>
          Hlavné výhody 
        </Typography>
            <List sx={{p:{xs:0, sm:2}}}>
              {items.map((item) => (  
                <ListItem sx={{p:{xs:1, md:2}}}>
                  <ListItemIcon>
                    <CheckIcon sx={{fontSize:{xs:25, md:35}}} color="secondary"/>
                  </ListItemIcon>
                  <ListItemText
                    sx={{color:"primary.contrastText"}}
                    primary={
                        <Typography sx={{ fontSize: {xs:20, md:24} }}>{item}</Typography>
                      }
                  />
                </ListItem>
              ))}
            </List>
        </Paper>
        </Slide>
        </Box>
        
    );
}