import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { baseurl } from '../../router';
import { NetsPoints } from '../../texts/serviceWindowText';
import ServiceCard from '../models/ServiceCard';




export default function NetsServicesGrid() {
    const navigate = useNavigate();
    return (
        <Box sx={{ width: '100%', pt: 10, p: { xs: 2, sm: 5,},  maxWidth:"1980px" }} id="services">
            <Grid
                container rowSpacing={1}
                rowGap={{ xs: 1, sm: 2, md: 3 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                columns={{ xs: 2, sm: 6, lg:12, xl: 18 }}
                sx={{
                    ml: { xl: 5, lg: 15, md: 5 },
                    mr: { xl: 5, lg: 15, md: 5 },
                }}>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/siete-proti-hmyzu/okenne-siete"); }}
                        description={NetsPoints["window"].description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/nets/window_net.webp"}
                        title="Okenné siete" />
                </Grid>
                    <Grid xs={6}>
                    <ServiceCard
                        openServiceDetails={() => { navigate(baseurl+"/siete-proti-hmyzu/dverove-siete"); }}
                        description={NetsPoints["door"].description}
                        imagesrc={process.env.PUBLIC_URL + "/pictures/nets/door_net.webp"}
                        title="Dverové siete" />
                </Grid>
            </Grid>
        </Box>
    );
}
