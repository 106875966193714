
// footerTexts.ts
export const footerTexts = {
    aboutUs: {
      title: "O nás",
      description: "S opravou a výmenou okien máme viac ako 20 ročné skúseností. Každý problém riešime jednoducho a efektívne, aby ste mali doma opäť komfort a bezpečie. Ak máte nejaké otázky, alebo potrebujete poradiť kontaktujte nás na telefónnom čísle, alebo pomocou kontaktného formulára.",
    },
    importantInfo: {
      title: "Dôležité informácie",
      items: [
        "Obchodné podmienky",
        "Reklamačný poriadok",
      ],
    },
    contact: {
        title: "Kontakt",
        items: [
            "Telefón: +421 911 671 234",
            "Alvipstav s.r.o.",
            "Dunajská 10",
            "040 01 Košice",
            "IČO: 56 231 130",
        ],
    }
  };