import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ContactForm from "./main/contactForm";
import Footer from "./main/footer";
import LandingPageHeader from "./main/landing-top";
import NavBar from "./main/nav-bar";
import QnaSection from "./main/QnaSection";
import WorkGallery from "./main/workGallery";
import ScrollTop from "./models/ScrollToTop";

export default function mainPage() {
  const location = useLocation();
  const targetId = location.state?.targetId;

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }

  }, [targetId]);
  return (
    <Box sx={{ width: '100%', position: 'realtive', backgroundColor: 'primary.main' }}>
      <Stack
        display='flex'
        direction="column"
        justifyContent="center"
        alignItems="center"

      >
        <NavBar />
        <LandingPageHeader />
          <WorkGallery />
          <QnaSection />
          <ContactForm />
          <Footer />
        <ScrollTop />
      </Stack>
    </Box>
  );
}