import { WindowNetsProps } from "./WindowNetsText";

export const DoorNetsText: WindowNetsProps = {
    title: "DVEROVÉ SIETE",
    subtitle: "VETRAJTE POHODLNE AJ CEZ DVERE",
    landingBox: "MÁME SIETE PROTI HMYZU NA VŠETKY TYPY DVERÍ Z HLINÍKA, DREVA A PLASTU.",
    image: "/pictures/top_bg.webp",
    nets:[
        {
            name: "HARMONIKOVÁ PLISSÉ SIEŤ",
            description: [
                "V súčasnosti neprekonateľná pre svoje kvality.",
            ],
            adventages: [
                "Pevný hliníkový profil 35/20, ktorý umožňuje montáž takmer kdekoľvek.",
                "Sieťovina sa skladá do seba, takže je nenáročná na priestor a nezavadzia.",
                "V porovnaní so všetkými ostatnými dostupnými dverovými sieťami je pohyb skutočne tichý a nerušivý.",
                "Možnosť vyhotovenia s nízkym aj vysokým prahom, podľa prahu dverí.",
                "Široká paleta farieb.",
                "Imitácia dreva.",
                "Individuálny nástrek v odtieňoch RAL."
            ],
            images: ["door_net.webp"],
        },
        {
            name: "DVEROVÁ OTVÁRACIA SIEŤ",
            description: [
                "",
            ],
            adventages: [
                "Masívny hliníkový profil 40/20.",
                "Spevnené vnútorné rohy pre vyššiu stabilitu siete.",
                "Klasické aj samozatváracie pánty.",
                "Zatváranie na magnet.",
                "Široká paleta farieb.",
                "Imitácia dreva.",
                "Individuálny nástrek v odtieňoch RAL.",
                "Možnosť zvoliť okopový prah."
            ],
            images: ["roll_net.webp"],
        },
        {
            name: "ROLOVACIA DVEROVÁ SIEŤ PROTI HMYZU",
            description: [
                "",
            ],
            adventages: [
                "Hliníkový profil.",
                "Rolovací bubon na pántovej strane dverí.",
                "Nenáročná na priestor.",
                "Široká paleta farieb.",
                "Imitácia dreva.",
                "Individuálny nástrek v odtieňoch RAL.",
            ],
            images: ["retractable-door.webp"],
        },
    ]
}
