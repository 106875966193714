import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import React from "react";
import HelpIcon from '@mui/icons-material/Help';
import ShippingIcon from '@mui/icons-material/LocalShipping';
import MeasureIcon from '@mui/icons-material/Straighten';
import ConstructionIcon from '@mui/icons-material/Construction';
import WarrantyIcon from '@mui/icons-material/GppGood';
import { useInView } from "react-intersection-observer";

function generate(element: React.ReactElement) {
    return [0, 1, 2, 3, 4, 5, 6].map((value) =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}

export default function WarrantyServices() {
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    return (
        <React.Fragment>
            <Typography fontWeight={800} color={"primary.dark"} sx={{ textAlign: "center",
                                fontSize: {
                                    xs: 'h4.fontSize',
                                    sm: 'h3.fontSize',
                                    md: 'h3.fontSize',
                                    lg: 'h3.fontSize',
                                    xl: 'h2.fontSize',
                                }
                            }} mb={5} mt={5} ml={5} mr={{ md: 0, xs: 5 }}>
                                Kompletný servis:
                            </Typography>
        <Grow in={inView} timeout={1000}>
        <Grid
        ref={ref}
            mb={5}
            container
            display={"flex"}
            justifyContent="center" // Center horizontally in the container
            alignItems="center"
            rowSpacing={1}
            rowGap={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 2, sm: 4, md: 6, lg: 8, xl: 8 }}
            sx={{
                pl: { xl: 5, lg: 15, md: 5 },
                pr: { xl: 5, lg: 15, md: 5 },
            }}>
            <Grid item display={"flex"} justifyContent="center" xs={2} >
                <Box sx={{ width: "90%", boxShadow: 10, p: 5, border: 4, minHeight:{xl:"270px",lg:"200px", md:"236px", sm:"130px", xs:"90px"}, borderColor: "primary.dark", borderRadius: 5 }}>
                    <Stack direction={"column"}>
                    <MeasureIcon  sx={{fontSize:55, color: "primary.dark"}}/>
                    <Typography  sx={{ ml:0, mt:0, fontSize:{
                        xl: "h4.fontSize",
                        lg: "h5.fontSize",
                        md: "h5.fontSize",
                        sm: "h6.fontSize",
                        xs: "h6.fontSize",
                    }}} fontWeight={800} >Zameranie</Typography>
                </Stack>
                </Box>
            </Grid>
            <Grid item display={"flex"} justifyContent="center" xs={2} >
                <Box sx={{ width: "90%", boxShadow: 10, p: 4, border: 4, minHeight:{xl:"270px",lg:"200px", md:"236px", sm:"130px", xs:"90px"}, borderColor: "primary.dark", borderRadius: 5 }}>
                    <Stack direction={"column"}>
                    <HelpIcon  sx={{fontSize:55, color: "primary.dark"}}/>
                    <Typography  sx={{ ml:0, mt:0, fontSize:{
                        xl: "h4.fontSize",
                        lg: "h5.fontSize",
                        md: "h5.fontSize",
                        sm: "h6.fontSize",
                        xs: "h6.fontSize",
                    }}} fontWeight={800} >Poradenstvo</Typography>
                </Stack>
                </Box>
            </Grid>
            <Grid item display={"flex"} justifyContent="center" xs={2} >
                <Box sx={{ width: "90%", boxShadow: 10, p: 4, 
                    minHeight:{xl:"270px",lg:"200px", md:"236px", sm:"130px", xs:"90px"},  border: 4, borderColor: "primary.dark", borderRadius: 5 }}>
                    <Stack direction={"column"} display="flex" >
                    <ConstructionIcon  sx={{fontSize:55, color: "primary.dark"}}/>
                    <Typography  sx={{ ml:0, mt:0, fontSize:{
                         xl: "h4.fontSize",
                         lg: "h5.fontSize",
                         md: "h5.fontSize",
                         sm: "h6.fontSize",
                         xs: "h6.fontSize",
                    }}} fontWeight={800} >Montáž</Typography>
                </Stack>
                </Box>
            </Grid>
            <Grid item display={"flex"} justifyContent="center" xs={2} >
                <Box sx={{ width: "90%", boxShadow: 10,  p:5, minHeight:{xl:"270px",lg:"200px", md:"236px", sm:"130px", xs:"90px"}, border: 4, borderColor: "primary.dark", borderRadius: 5 }}>
                    <Stack direction={"column"}>
                    <ShippingIcon  sx={{fontSize:55, color: "primary.dark"}}/>
                    <Typography  sx={{ ml:0, mt:0, fontSize:{
                         xl: "h4.fontSize",
                         lg: "h5.fontSize",
                         md: "h5.fontSize",
                         sm: "h6.fontSize",
                         xs: "h6.fontSize",
                    }}} fontWeight={800} >Doprava</Typography>
                </Stack>
                </Box>
            </Grid>
            <Grid item display={"flex"} justifyContent="center" xs={2} >
                <Box sx={{ width: "90%", boxShadow: 10, minHeight:{xl:"270px",lg:"200px", md:"236px", sm:"130px", xs:"90px"}, p: 4, border: 4, borderColor: "primary.dark", borderRadius: 5 }}>
                    <Stack direction={"column"}>
                    <WarrantyIcon  sx={{fontSize:55, color: "primary.dark"}}/>
                    <Typography  sx={{ ml:0, mt:0, fontSize:{
                        xl: "h4.fontSize",
                        lg: "h5.fontSize",
                        md: "h5.fontSize",
                        sm: "h6.fontSize",
                        xs: "h6.fontSize",
                    }}} fontWeight={800} >Záručný a pozáručný servis</Typography>
                </Stack>
                </Box>
            </Grid>

        </Grid>
        </Grow>
        </React.Fragment>
    );
}