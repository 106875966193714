import { Box, Container, Divider, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React from "react";
import { QNAtext } from "../../texts/qnaText";
import theme from "../../theme";
import QnaBubble from "./qnaBubble";

interface QnaSectionModelProps {
    texts: QNAtext;
}

const QNA = styled(Container)(({ theme }) => ({
    backgroundImage:
        `url(${process.env.PUBLIC_URL + "/pictures/qna_bg.webp"
        })`,
    backgroundSize: 'cover',
    width: '100vw',
    height: 'auto',
}));

export const  QnaModel: React.FC<QnaSectionModelProps> = ({ texts }) =>  {
    
    return(<QNA
        id='qna'
        disableGutters
        maxWidth={false}
    >
        <Box sx={{
            backgroundColor: alpha(theme.palette.secondary.light, 0.75),
            boxShadow: 0,
            padding: { xs: 0, md: 10, xl: 25 },
            height: 'auto',
            width: '100vw',
        }}>
            <Typography
                sx={{
                    textAlign: 'center',
                    fontSize: {
                        xs: 'h5.fontSize',
                        sm: 'h4.fontSize',
                        md: 'h4.fontSize',
                        lg: 'h3.fontSize',
                    }
                }} >
                {texts.title}
            </Typography>
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: 5,
                }}                
            >
                <Divider sx={{ backgroundColor: 'black', mt: 2, mb: 2, height: {xs:5, md:7}, 
                    width:{xs:100, sm: 200, md: 300, lg: 400} }} />
            </Box>
            <Container maxWidth="xl" >
               {texts.questions.map((qna) => (
                    <QnaBubble  question={qna.question} answer={qna.answer} />
                 ))}
            </Container>
        </Box>
    </QNA>
    );
}