import { ServiceCard } from "./servicesText";



export const MainServices: ServiceCard[] = [
    {
        imagesrc: "/pictures/window_repair.webp",
        titles: [
            {
                content: "Servis okien",
                link: "/",
                targetId: ""
            }
        ],
        points: [
            {
                content: "Oprava a výmena kovania",
                link: "/oprava-vymena-kovania",
                targetId: ""
            },
            {
                content: "Zaseknuté okná a dvere",
                link: "/zaseknute-okna-dvere",
                targetId: ""
            },
            {
                content: "Nastavenie zimného/letného režimu",
                link: "/zimny-letny-rezim",
                targetId: ""
            },
            {
                content: "Pravidelná údržba",
                link: "/pravidelna-udrzba",
                targetId: ""
            },
            {
                content: "Výmena tesnení",
                link: "/vymena-tesneni",
                targetId: ""
            },
        ]
    },
    {
        imagesrc: "/pictures/window_blinds.webp",
        titles: [
            {
                content: "Žalúzie",
                link: "/tieniaca-technika",
                targetId: ""
            },
            {
                content: "Sieťky proti hmyzu",
                link: "/siete-proti-hmyzu",
                targetId: ""
            }
        ],
        points: [
            {
                content: "Oprava a výmena žalúzií",
                link: "/tieniaca-technika",
                targetId: ""
            },
            {
                content: "Montáž nových žalúzií",
                link: "/tieniaca-technika",
                targetId: ""
            },
            {
                content: "Vonkajšia tieniaca technika",
                link: "/tieniaca-technika",
                targetId: ""
            },
            {
                content: "Sieťky proti hmyzu",
                link: "/siete-proti-hmyzu",
                targetId: ""
            },
        ]
    },
    {
        imagesrc: "/pictures/windowsill2.webp",
        titles: [
            {
                content: "Pretesnenie a výmena parapiet",
                link: "/",
                targetId: ""
            }
        ],
        points: [
            {
                content: "Inštalácia parapiet",
                link: "/",
                targetId: ""
            },
            {
                content: "Výmena poškodených parapiet",
                link: "/",
                targetId: ""
            },
            {
                content: "Pretesnenie parapiet",
                link: "/",
                targetId: ""
            },
            {
                content: "Oprava parapiet",
                link: "/",
                targetId: ""
            }
        ]
    },
    {
        imagesrc: "/pictures/van2.webp",
        titles: [
            {
                content: "Havarijný servis",
                link: "/havarijny-servis",
                targetId: ""
            }
        ],
        points: [
            {
                content: "Rýchla pomoc pri rôznych poruchách", 
                link: "/havarijny-servis",
                targetId: ""
            },
            {
                content:  "Aj mimo pracovných hodín",
                link: "/havarijny-servis",
                targetId: ""
            },
        ]
    },
    {
        imagesrc: "/pictures/boy.webp",
        titles: [
            {
                content: "Ťahá Vám cez okná?",
                link: "/",
                targetId: ""
            }
        ],
        points: [
            {
                content: "Výmena tesnenia",
                link: "/vymena-tesneni",
                targetId: ""
            },
            {
                content: "Pretesnenie parapiet",
                link: "/",
                targetId: ""
            },
    
        ]
    }
];





