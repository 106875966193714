import CheckIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, Fade, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { DoorNetsText } from "../../texts/products/DoorNetsText";
import ContactForm from "../main/contactForm";
import Footer from "../main/footer";
import NavBar from "../main/nav-bar";
import { NetsLanding } from "../main/netsLanding";
import { ProductDescription } from "../models/productDescription";
import { ProductList } from "../models/productList";
import ScrollTop from "../models/ScrollToTop";



const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
    textAlign: 'center',
}));


export const DoorNets = () => {
    const location = useLocation();
    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    
    const doorType = ["Balkónové","Terasové","Presuvné", "Vchodové","1 aj 2 krídlové"] ;
    return (
        <Box sx={{ width: '100%', position: 'realtive', backgroundColor: 'secondary.light' }}>
            <Stack
                display='flex'
                direction="column"
                justifyContent="center"
                alignItems="center"

            >
                <NavBar />
                <NetsLanding title={DoorNetsText.title} subtitle={DoorNetsText.subtitle} landingBox={DoorNetsText.landingBox} image={DoorNetsText.image} />
                {DoorNetsText.nets.map((prd) => (
                    <React.Fragment>
                        <Grid                        
                            container
                            
                            width={"100%"}
                            display={"flex"}
                            maxWidth={"1920px"}
                            rowSpacing={1}
                            rowGap={{ xs: 1, sm: 2, md: 3 }}
                            columns={{ xs: 9, sm: 9, lg: 18, xl: 18 }}
                            sx={{
                                m: 0, p: 0
                            }}>
                            <Grid xs={9}>
                                <ProductDescription folder="nets" title={prd.name} description={prd.description} images={prd.images} />
                            </Grid>
                            <Grid xs={9}>
                                <ProductList items={prd.adventages} />
                            </Grid>

                        </Grid>
                    </React.Fragment>
                ))}
                <Fade in={inView} timeout={1000}>
                 <StyledBox 
                 ref={ref}
                 sx={{
                    borderRadius: { xs: 5, sm: 15 },
                    boxShadow: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    p: { xs: 3, sm: 5, lg:10 },
                    m: { xs: 3, sm: 10 }
                }}>
                    
                    <Typography
                        fontWeight={800}
                        color="secondary.light"
                        sx={{
                            fontSize: {
                                xs: 'h5.fontSize',
                                sm: 'h4.fontSize',
                                md: 'h4.fontSize',
                                lg: 'h3.fontSize',
                            },
                        }}
                    >Siete montujeme na dvere:</Typography>
                <List sx={{ p: { xs: 0, sm: 2 } }}>
                        {doorType.map((item) => (
                            <ListItem sx={{ p: { xs: 1, md: 2 } }}>
                                <ListItemIcon>
                                    <CheckIcon sx={{ fontSize: { xs: 25, md: 35 } }} color="secondary" />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ color:"secondary.light" }}
                                    primary={
                                        <Typography sx={{ fontSize: { xs: 20, md: 24 } }}>{item}</Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </StyledBox>
                </Fade>
                <ContactForm />
                <Footer />
                <ScrollTop />
            </Stack>
        </Box>
    );
}